import '../App.css';
import React, { useState, useEffect } from "react";
import config from '../config.json';
import Table from './Table';
import Create from './Create';
import loading from './loading.gif';

export default function Home({ form, setForm }) {

  const [entryNum, setEntryNum] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // async function load() {
    //   // countEntries();
    //   console.log('attempting to reload');
    //   window.location.reload(true);
    // }
    async function countEntries() {
      try {
        const response = await fetch(`http://${config.server_host}/get/`);
        const result = await response.json();
        setEntryNum(Object.keys(result).length);
        setIsLoaded(true);
        // return Object.keys(result).length;
      } catch (err) {
        setTimeout(10000, window.location.reload(true));
      }
    }
    countEntries();
  }, [])

  if (!isLoaded) {
    return (
      <div className="loading-container">
        <div className="loading-content">
          <img src={loading} alt="loading animation of a person walking" className="gif" />
          <p>Hiking to the viewpoint... <br/> If you feel like you're waiting for too long, please reload this page! Loading can take up to 30 seconds</p>
        </div>
      </div>
    )
  } else return (
    <div className="Home">
      <div className="title">
        <div className="title-text">Internet Hiker's Log</div>
        <div className="entry-counter">{entryNum} entries</div>
      </div>
      <div className="container">
        <div className="left-side">
          <div className="description">
            <div className="greeting">(〃^∇^)ﾉ Welcome, fellow Internet Hiker. Take a brief rest, log your thoughts, and stay for as little or as long as you would like.
            <br/><br/>&#40;P.S. If you don't see any data in the table, wait a few seconds and reload&#41;</div>
            <br/>
            <div className="description-content">
              <mark><u>What this site is:</u></mark> A space in the internet for slowness and reflection. Every person has had their own experiences both online
              and offline, yet many people stumble across the same corners of the internet. This website serves as a place for people to log
              their thoughts, experiences, and perspectives in whatever stage of life they are currently in and to reflect upon the experiences
              of others who have visited the website before.
              <br/> <br/>
              <mark><u>How the data is used:</u></mark> All data collected on this site is anonymous and will not used be for any purposes beyond being displayed on
              this site for others to view.
              <br/> <br/>
              <mark><u>Why this site was made:</u></mark> This site was created as a part of the 2023 senior undergraduate design exhibition at the University of Pennsylvania.
            </div>
          </div>
          <div className="form-group">
            <div className="form-title">Create a log entry</div>
            <Create form={form} setForm={setForm} className="main-form" />
          </div>
        </div>
        <div className="right-side">
          <Table form={form} />
          <div className="right-side-gap" />
        </div>
      </div>
    </div>
  )
}