import React, { useState, useEffect } from "react";
import '../App.css';

export default function Dropdowns({ form, setForm }) {

  const [selectedFont, setSelectedFont] = useState(['Serif']);
  const [selectedColor, setSelectedColor] = useState(['Yellow']);
  const [translatedColor, setTranslatedColor] = useState(['#FFEECD']);
  const [translatedFont, setTranslatedFont] = useState(['Georgia']);

  /* Shows dropdown on click */
  function toggleFont(e) {
    e.preventDefault();
    document.getElementById('font-drop-content').classList.toggle('show');
    toggleFontArrow();
  }

  function toggleColor(e) {
    e.preventDefault();
    document.getElementById('color-drop-content').classList.toggle('show');
    toggleColorArrow();
  }

  /* Handles arrow toggling */
  function toggleColorArrow() {
    const upObj = document.getElementById('upColor');
    const upObjStyles = window.getComputedStyle(upObj);
    const upStatus = upObjStyles.getPropertyValue("display");

    const downObj = document.getElementById('downColor');
    const downObjStyles = window.getComputedStyle(downObj);
    const downStatus = downObjStyles.getPropertyValue("display");

    if (upStatus === "none") {
      document.getElementById('upColor').style.display = "block";
    } else if (upStatus === "block") {
      document.getElementById('upColor').style.display = "none";
    }

    if (downStatus === "none") {
      document.getElementById('downColor').style.display = "block";
    } else if (downStatus === "block") {
      document.getElementById('downColor').style.display = "none";
    }
  }

  function toggleFontArrow() {
    const upObj = document.getElementById('upFont');
    const upObjStyles = window.getComputedStyle(upObj);
    const upStatus = upObjStyles.getPropertyValue("display");

    const downObj = document.getElementById('downFont');
    const downObjStyles = window.getComputedStyle(downObj);
    const downStatus = downObjStyles.getPropertyValue("display");

    if (upStatus === "none") {
      document.getElementById('upFont').style.display = "block";
    } else if (upStatus === "block") {
      document.getElementById('upFont').style.display = "none";
    }

    if (downStatus === "none") {
      document.getElementById('downFont').style.display = "block";
    } else if (downStatus === "block") {
      document.getElementById('downFont').style.display = "none";
    }
  }

  /* Close dropdown when user clicks off */
  window.onclick = function(e) {
    if (!e.target.matches('.dropbtn')) {
      var myDropdown = document.getElementById("font-drop-content");
      if (myDropdown.classList.contains('show')) {
        myDropdown.classList.remove('show');
        toggleFontArrow();
      }
      var colorDropdown = document.getElementById("color-drop-content");
      if (colorDropdown.classList.contains('show')) {
        colorDropdown.classList.remove('show');
        toggleColorArrow();
      }
    }
  }

  const updateSelectedFont = async (input) => {
    if (input === "Georgia") {
      await setSelectedFont("Serif");
      await setTranslatedFont(input);
    }
    if (input === "Trebuchet MS") {
      await setSelectedFont("Sans serif");
      await setTranslatedFont(input);
    }
    if (input === "Courier") {
      await setSelectedFont("Monospace");
      await setTranslatedFont(input);
    }
    if (input === "Cursive") {
      await setSelectedFont("Cursive");
      await setTranslatedFont(input);
    }

    await setForm({
      date: form.date,
      initials: form.initials,
      location: form.location,
      weather: form.weather,
      happy: form.happy,
      thought: form.thought,
      font: input,
      color: form.color,
    });
  }

  const updateSelectedColor = async (input) => {
    if (input === "#FFEECD") {
      document.getElementById("yellow").style.border = "solid #f2d08f 3.5px";
      document.getElementById("orange").style.border = "none";
      document.getElementById("green").style.border = "none";
      document.getElementById("blue").style.border = "none";
      document.getElementById("purple").style.border = "none";
      await setSelectedColor("Yellow");
      await setTranslatedColor(input);
    }
    if (input === "#FFE6CE") {
      document.getElementById("orange").style.border = "solid #e8ba8e 3.5px";
      document.getElementById("yellow").style.border = "none";
      document.getElementById("green").style.border = "none";
      document.getElementById("blue").style.border = "none";
      document.getElementById("purple").style.border = "none";
      await setSelectedColor("Orange");
      await setTranslatedColor(input);
    }
    if (input === "#E2EED5") {
      document.getElementById("green").style.border = "solid #c4dbab 3.5px";
      document.getElementById("yellow").style.border = "none";
      document.getElementById("orange").style.border = "none";
      document.getElementById("blue").style.border = "none";
      document.getElementById("purple").style.border = "none";
      await setSelectedColor("Green");
      await setTranslatedColor(input);
    }
    if (input === "#F1F6F6") {
      document.getElementById("blue").style.border = "solid #a9d1d1 3.5px";
      document.getElementById("yellow").style.border = "none";
      document.getElementById("orange").style.border = "none";
      document.getElementById("green").style.border = "none";
      document.getElementById("purple").style.border = "none";
      await setSelectedColor("Blue");
      await setTranslatedColor(input);
    }
    if (input === "#F3ECF8") {
      document.getElementById("purple").style.border = "solid #c0abcf 3.5px";
      document.getElementById("yellow").style.border = "none";
      document.getElementById("orange").style.border = "none";
      document.getElementById("green").style.border = "none";
      document.getElementById("blue").style.border = "none";
      await setSelectedColor("Purple");
      await setTranslatedColor(input);
    }

    await setForm({
      date: form.date,
      initials: form.initials,
      location: form.location,
      weather: form.weather,
      happy: form.happy,
      thought: form.thought,
      font: form.font,
      color: input,
    });
  }

  return (
    <div className="dropdown-container">
      <label>Log entry color</label><br></br>
      <div className="color-drop">
        <button className="dropbtn" onClick={toggleColor} style={{ backgroundColor: translatedColor }}>
          {selectedColor}
          <div className="down-arrow" id="downColor">▼</div>
          <div className="up-arrow" id="upColor">▲</div>
        </button>
        <div className="drop-content" id="color-drop-content">
          <div onClick={() => updateSelectedColor('#FFEECD')} className="drop-item" id="yellow">Yellow</div>
          <div onClick={() => updateSelectedColor('#FFE6CE')} className="drop-item" id="orange">Orange</div>
          <div onClick={() => updateSelectedColor('#E2EED5')} className="drop-item" id="green">Green</div>
          <div onClick={() => updateSelectedColor('#F1F6F6')} className="drop-item" id="blue">Blue</div>
          <div onClick={() => updateSelectedColor('#F3ECF8')} className="drop-item" id="purple">Purple</div>
        </div>
      </div>

      <div className="dropdown-spacer"></div>

      <label>Log entry font</label><br></br>
      <div className="font-drop">
        <button className="dropbtn" onClick={toggleFont} style={{ fontFamily: translatedFont }}>
          {selectedFont}
          <div className="down-arrow" id="downFont">▼</div>
          <div className="up-arrow" id="upFont">▲</div>
        </button>
        <div className="drop-content" id="font-drop-content">
          <div onClick={() => updateSelectedFont('Georgia')} className="drop-item" id="serif">Serif</div>
          <div onClick={() => updateSelectedFont('Trebuchet MS')} className="drop-item" id="sans-serif">Sans serif</div>
          <div onClick={() => updateSelectedFont('Courier')} className="drop-item" id="monospace">Monospace</div>
          <div onClick={() => updateSelectedFont('Cursive')} className="drop-item" id="cursive">Cursive</div>
        </div>
      </div>
  </div>
  )
}