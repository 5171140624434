import './App.css';
import React, { useState } from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import Home from './components/Home';

function App() {
  const [form, setForm] = useState({
    date: "",
    initials: "",
    location: "",
    weather: "",
    happy: "",
    thought: "",
    font: "Georgia",
    color: "#FFEECD",
  });

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home form={form} setForm={setForm} />} />
      </Routes>
    </div>
  );
}

export default App;
