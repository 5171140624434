import React, { useState, useEffect } from "react";
import config from '../config.json';
import Dropdowns from './Dropdowns';

export default function Create({ form, setForm }) {

  async function handleDate() {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    const today = mm + '/' + dd + '/' + yyyy;

    await setForm({
      date: today,
      initials: form.initials,
      location: form.location,
      weather: form.weather,
      happy: form.happy,
      thought: form.thought,
      font: form.font,
      color: form.color,
    });
  }

  useEffect(() => {
    handleDate();
  }, [])

  // handles submission to backend
  async function onSubmit(e) {
    e.preventDefault();

    // Resetting error states
    document.getElementById("empty-error").style.display = "none";
    document.getElementById("character-error").style.display = "none";
    document.getElementById("initials-field").style.borderColor = "black";
    document.getElementById("initials-field").style.borderWidth = "1px";
    document.getElementById("location-field").style.borderColor = "black";
    document.getElementById("location-field").style.borderWidth = "1px";
    document.getElementById("weather-field").style.borderColor = "black";
    document.getElementById("weather-field").style.borderWidth = "1px";
    document.getElementById("happy-field").style.borderColor = "black";
    document.getElementById("happy-field").style.borderWidth = "1px";
    document.getElementById("thought-field").style.borderColor = "black";
    document.getElementById("thought-field").style.borderWidth = "1px";
    
    // Checking for empty form inputs or ">" / "<"
    const initials = document.forms["entry-form"]["initials"].value;
    const location = document.forms["entry-form"]["location"].value;
    const weather = document.forms["entry-form"]["weather"].value;
    const happy = document.forms["entry-form"]["happy"].value;
    const thought = document.forms["entry-form"]["thought"].value;
    
    if (location == null || location === "" || !location.replace(/\s/g, '').length) {
      document.getElementById("empty-error").style.display = "block";
      document.getElementById("location-field").style.borderColor = "#ff3b3b";
      document.getElementById("location-field").style.borderWidth = "3px";
    } else if (location.includes("<") || location.includes(">")) {
      document.getElementById("character-error").style.display = "block";
      document.getElementById("location-field").style.borderColor = "#ff3b3b";
      document.getElementById("location-field").style.borderWidth = "3px";
    } else if (initials == null || initials === "" || !initials.replace(/\s/g, '').length) {
      document.getElementById("empty-error").style.display = "block";
      document.getElementById("initials-field").style.borderColor = "#ff3b3b";
      document.getElementById("initials-field").style.borderWidth = "3px";
    } else if (initials.includes("<") || initials.includes(">")) {
      document.getElementById("character-error").style.display = "block";
      document.getElementById("initials-field").style.borderColor = "#ff3b3b";
      document.getElementById("initials-field").style.borderWidth = "3px";
    } else if (weather == null || weather === "" || !weather.replace(/\s/g, '').length) {
      document.getElementById("empty-error").style.display = "block";
      document.getElementById("weather-field").style.borderColor = "#ff3b3b";
      document.getElementById("weather-field").style.borderWidth = "3px";
    } else if (weather.includes("<") || weather.includes(">")) {
      document.getElementById("character-error").style.display = "block";
      document.getElementById("weather-field").style.borderColor = "#ff3b3b";
      document.getElementById("weather-field").style.borderWidth = "3px";
    } else if (happy == null || happy === "" || !happy.replace(/\s/g, '').length) {
      document.getElementById("empty-error").style.display = "block";
      document.getElementById("happy-field").style.borderColor = "#ff3b3b";
      document.getElementById("happy-field").style.borderWidth = "3px";
    } else if (happy.includes("<") || happy.includes(">")) {
      document.getElementById("character-error").style.display = "block";
      document.getElementById("happy-field").style.borderColor = "#ff3b3b";
      document.getElementById("happy-field").style.borderWidth = "3px";
    } else if (thought == null || thought === "" || !thought.replace(/\s/g, '').length) {
      document.getElementById("empty-error").style.display = "block";
      document.getElementById("thought-field").style.borderColor = "#ff3b3b";
      document.getElementById("thought-field").style.borderWidth = "3px";
    } else if (thought.includes("<") || thought.includes(">")) {
      document.getElementById("character-error").style.display = "block";
      document.getElementById("thought-field").style.borderColor = "#ff3b3b";
      document.getElementById("thought-field").style.borderWidth = "3px";
    } else {
      // When a post request is sent to the create url, we'll add a new record to the database.
      const newPerson = { ...form };

      await fetch(`http://${config.server_host}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPerson),
      })
      .catch(error => {
        window.alert(error);
        return;
      });
    
      setForm({ date: "", initials: "", location: "", weather: "", happy: "", thought: "", font: "Serif", color: "#FFEECD" });
      window.location.reload();
    }
  }

  // handles when user is inputting answers
  const handleInitials = async (event) => {
    setForm({
      date: form.date,
      initials: event.target.value,
      location: form.location,
      weather: form.weather,
      happy: form.happy,
      thought: form.thought,
      font: form.font,
      color: form.color,
    });
  }

  const handleLocation = async (event) => {
    setForm({
      date: form.date,
      initials: form.initials,
      location: event.target.value,
      weather: form.weather,
      happy: form.happy,
      thought: form.thought,
      font: form.font,
      color: form.color,
    });
  }

  const handleWeather = async (event) => {
    setForm({
      date: form.date,
      initials: form.initials,
      location: form.location,
      weather: event.target.value,
      happy: form.happy,
      thought: form.thought,
      font: form.font,
      color: form.color,
    });
  }

  const handleHappy = async (event) => {
    setForm({
      date: form.date,
      initials: form.initials,
      location: form.location,
      weather: form.weather,
      happy: event.target.value,
      thought: form.thought,
      font: form.font,
      color: form.color,
    });
  }

  const handleThought = async (event) => {
    setForm({
      date: form.date,
      initials: form.initials,
      location: form.location,
      weather: form.weather,
      happy: form.happy,
      thought: event.target.value,
      font: form.font,
      color: form.color,
    });
  }

  return (
    <div className="Create">
      <form className="form" id="entry-form">
        <div id="empty-error">*Fill out all fields!*</div>
        <div id="character-error">*&lt; and &gt; characters are not allowed*</div>
        <label>Your initials</label><br></br>
        <input type="text" name="initials" id="initials-field" value={form.initials} onChange={handleInitials}></input><br></br>
        <label>Location</label><br></br>
        <input type="text" name="location" id="location-field" value={form.location} onChange={handleLocation}></input><br></br>
        <label>What's the weather like?</label><br></br>
        <input type="text" name="weather" id="weather-field" value={form.weather} onChange={handleWeather}></input><br></br>
        <label>What makes you happy?</label><br></br>
        <textarea type="text" name="happy" id="happy-field" value={form.happy} onChange={handleHappy}></textarea><br></br>
        <label>Most important thing you've learned in the past year?</label><br></br>
        <textarea type="text" name="thought" id="thought-field" value={form.thought} onChange={handleThought}></textarea><br></br>
        <Dropdowns form={form} setForm={setForm} />
        <input type="submit" value="Submit" onClick={(e) => onSubmit(e)}></input>
      </form>
    </div>
  )
}