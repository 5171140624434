import React, { useState, useEffect } from "react";
import config from '../config.json';

export default function Table({ form }) {

  const [records, setRecords] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  async function getRecords() {
    const response = await fetch(`http://${config.server_host}/get/`);
    const result = await response.json();
    setRecords(result);

    // Displaying data on table
    const toDisplay = result.map(
      (info) => {
        return (
          <tr key={info._id} style={{ fontFamily: info.font, backgroundColor: info.color }}>
            <td>{info.date}</td>
            <td>{info.initials}</td>
            <td>{info.location}</td>
            <td>{info.weather}</td>
            <td>{info.happy}</td>
            <td>{info.thought}</td>
          </tr>
        )
      }
    ).reverse();
    setDisplayData(toDisplay);
  }

  // Retrieving records on page load
  useEffect(() => {
    getRecords();
    // applyFont();
  }, [])

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Initials</th>
          <th>Location</th>
          <th className="long-th">What's the weather like?</th>
          <th className="extra-long-th">What makes you happy?</th>
          <th className="extra-long-th">Most important thing you've learned in the past year?</th>
        </tr>
      </thead>
      <tbody>
        {displayData}
      </tbody>
    </table>
  )
}